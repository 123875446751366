import React, { FC, useState, useEffect } from 'react';
import './App.css';
import { useAuth0 } from './auth/wrapper';
import '@elastic/eui/dist/eui_theme_light.css';
import LoadingPage from './Pages/LoadingPage';
import LoginPage from './Pages/LoginPage';
import NoAccessPage from './Pages/NoAccessPage';
import CustomErrorPage from './Pages/CustomErrorPage';
import Browser from './Browser';
import {ErrorBoundary} from 'react-error-boundary';
import {EuiButton,EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiText, EuiTitle,EuiSpacer } from "@elastic/eui";
import { useHistory } from 'react-router-dom';

interface IAccountProps {
  role: string;
  email: string | null;
  decryptKey: string | null;
}

export const App: FC = () => {
  const { loading, isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0()

  const [acc, setAcc] = useState<IAccountProps>({
    role: 'external_user',
    email: null,
    decryptKey: null,
  });

  const whitelist = ["onarchipelago.com"];

  const hasAccess = () => {
    const allowedDomain = whitelist.some(
      (domain) => {
        const emailSplit = (acc.email || '').split('@');
        if (emailSplit.length < 2) {
          return false
        }
        return emailSplit[emailSplit.length - 1].toLowerCase() === domain;
      }
    );
    return allowedDomain || acc.role.toLowerCase() === 'archipelago_user';
  }

  useEffect(() => {
    if (getIdTokenClaims) {
      (async () => {
        const claims = await getIdTokenClaims() as any;

        // https://auth0.com/docs/tokens/create-namespaced-custom-claims
        const authNamespace = 'https://authorization';
        const userNamespcae = 'https://onarchipelago.com/user';
        const schemaNamespace = 'https://schema-browser';
        
        let role = acc.role;
        let email: string | null = null;
        let decryptKey: string | null = null;

        if (claims){
          // Role
          if (claims[authNamespace] && claims[authNamespace].role) {
            role = claims[authNamespace].role;
          }

          // Email
          if (claims[userNamespcae] && claims[userNamespcae].email) {
            email = claims[userNamespcae].email;
          }

          // Decryption Key
          if (claims[schemaNamespace] && claims[schemaNamespace].decrypt_key) {
            decryptKey = claims[schemaNamespace].decrypt_key;
          }
        }

        // Set state
        setAcc({
          role: role,
          email: email,
          decryptKey: decryptKey,
        });
      })();
    }
  }, [getIdTokenClaims])

  if (loading) {
    return <LoadingPage />
  } else if (!isAuthenticated) {
    return <LoginPage loginWithRedirect={loginWithRedirect} />
  } else if (!hasAccess()) {
    return <NoAccessPage />
  } else if (acc.decryptKey) {
    return <ErrorBoundary FallbackComponent={Fallback}><Browser decryptKey={acc.decryptKey} /></ErrorBoundary>;
  } else {
    return <CustomErrorPage message={'Can\'t decode schema docs.'} />
  }
}

export default App;

const Fallback: FC<{
  error:any,
  resetErrorBoundary: any,
}> = ({ error, resetErrorBoundary }) =>{
  const history = useHistory();

  return <EuiPage style={{ height: "100vh" }}>
    <EuiPageBody>
      <EuiPageContent verticalPosition="center" horizontalPosition="center">
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
            <p>Something went wrong:</p>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiText>
            <pre style={{ color: "red" }}>{error.message}</pre>
          </EuiText>
          <EuiSpacer size="xl" />
            <EuiText textAlign='center'>
            <EuiButton fill onClick={() => {
              history.push({
                pathname: `/logout`,
              });
            }}>
              Log out
            </EuiButton>
          </EuiText>
        </EuiPageContentBody>
      </EuiPageContent>
    </EuiPageBody>
  </EuiPage>
}
