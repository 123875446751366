import React, { FC } from 'react';
import { 
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiDescriptionList,
} from '@elastic/eui';
import { acc_lvl3_2_prefix } from './YFMRender';
interface ISecondaryModifierAccordion {
    secondaryModifier: any;
    index: number;
    hideEmpty: boolean;
    accordionState: {[index:string]:boolean}
    onAccordionChange: (accordionId: string, open: boolean) => void;
}

const getListValueItems = (hideIfEmpty: boolean, secondaryModifier: any) => {
    const descriptions = [
        {
            title: 'Coding',
            description: secondaryModifier.coding,
        },
        {
            title: 'Model',
            description: secondaryModifier.model,
        },
        {
            title: 'Field name',
            description: secondaryModifier.fieldName,
        },
        {
            title: 'Code name',
            description: secondaryModifier.codeName,
        },
    ];
    return descriptions;
}

export const SecondaryModifierAccordion: FC<ISecondaryModifierAccordion> = ({
    secondaryModifier,
    index,
    hideEmpty,
    accordionState,
    onAccordionChange
}): JSX.Element => {
    const accordionLvl3_2Id = acc_lvl3_2_prefix + index;
    return (
        <>
          <EuiAccordion
            id="id2"
            buttonContent="Secondary modifier"
            paddingSize="s"
            className="yfmAccordion"
            forceState={accordionState[accordionLvl3_2Id] ? 'open' : 'closed'}
            onToggle={(isOpen) => {
              onAccordionChange(accordionLvl3_2Id, !accordionState[accordionLvl3_2Id]);
            }}
          >
            <EuiText>
              <EuiDescriptionList
                type="responsiveColumn"
                textStyle="reverse"
                listItems={getListValueItems(hideEmpty, secondaryModifier)}
                descriptionProps={{ className: "eui-textBreakWord" }}
                style={{ maxWidth: '330px' }}
              />
            </EuiText>
            
          </EuiAccordion>
          <EuiSpacer size="s" />
        </>
      );
}