import React, { FC, useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Callback from './Callback';
import LogoutPage from './Pages/LogoutPage';
import { useAuth0 } from './auth/wrapper';
import mixpanel from 'mixpanel-browser';

const Routes: FC = () => {
  const { loading, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (!loading && isAuthenticated && user) {
      if (user['https://onarchipelago.com/user']) {
        const userId = user['https://onarchipelago.com/user'].user_id;
        if (userId) {
          // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelidentify
          mixpanel.identify(user.email);
          // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelregister
          mixpanel.register({ email: user.email });
          // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpaneltrack
          mixpanel.track(`Schema Docs Browser: Open`, {
            event_surface: 'SchemaDocsBrowser',
          });
        }
      }
    }
  }, [loading, isAuthenticated, user]);

  return (
    <Router>
      <Switch>
        <Route
          exact={true}
          strict={true}
          path={'/logout'}
          render={(props: any) => {
            return <LogoutPage />;
          }}
        />
        <Route
          exact={true}
          strict={true}
          path={'/callback'}
          render={(props: any) => {
            return <Callback />;
          }}
        />
        <Route
          exact={false}
          strict={false}
          path={'/'}
          render={(props: any) => <App />}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
