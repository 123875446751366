import { EuiLoadingSpinner, EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody } from "@elastic/eui";
import React, { FC } from "react";

export const LoadingPage: FC = () => {
  return <EuiPage style={{ height: "100vh" }}>
    <EuiPageBody>
      <EuiPageContent verticalPosition="center" horizontalPosition="center">
        <EuiPageContentBody style={{ textAlign: 'center'}}>
          <EuiLoadingSpinner size="xl" />
        </EuiPageContentBody>
      </EuiPageContent>
    </EuiPageBody>
  </EuiPage>
}

export default LoadingPage;