import React, { FC } from 'react';
import Markdown from 'markdown-to-jsx';
import { Attribute } from '../types';
import { imagesMap } from '../../gen/outputImageFile';

interface MarkdownWrapType {
  attribute?: Attribute
}

const Image = ({ ...props }) => {
  let src = props.src;
  if (src.startsWith('../')) {
    src = src.slice(3);
  }
  var srcOverride = src;
  for (let i = 0; i < imagesMap.length; i++) {
    var z = imagesMap[i];
    if (z.key === src) {
      srcOverride = z.value;
    }
  }
  
  props.className = props.className || "";
  if (!props.className.includes('markdown-img')) {
    props.className += " markdown-img";
  }
  return (<img alt="" {...props}  src={srcOverride} />);
}

export const MarkdownWrap: FC<MarkdownWrapType> = ({
  attribute,
}) => {
  const options = {
    overrides: {
      img: { component: Image }
    }
  };

  return (
    <div className="markdown-page">
      <Markdown className="markdown-body" options={options}>{attribute?.markdown || ''}</Markdown>
    </div>
  );
}