import React, { FC } from 'react';
import { 
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiDescriptionList,
} from '@elastic/eui';
import { acc_lvl4_prefix } from './YFMRender';

const nullOrEmpty = (value: any): boolean => {
  return value === null || value === '';
}

const getExternalMappingItems = (hideIfEmpty: boolean, mapping: any) => {
  const descriptions = [];
  if (!hideIfEmpty ? true : !nullOrEmpty(mapping.externalCoding))
    descriptions.push({title: 'External Coding', description: mapping.externalCoding});
  if (!hideIfEmpty ? true : !nullOrEmpty(mapping.valueId))
    descriptions.push({title: 'Value Id', description: mapping.valueId});
  if (!hideIfEmpty ? true : !nullOrEmpty(mapping.value))
    descriptions.push({title: 'Value', description: mapping.value});

  return descriptions;
}

interface IExternalMappingsAccordion {
  mapping: any,
  index: number,
  hideEmpty: boolean,
  accordionState: {[index:string]:boolean}
  onAccordionChange: (accordionId: string, open: boolean) => void;
}

export const ExternalMappingsAccordion: FC<IExternalMappingsAccordion> = ({
  mapping,
  index,
  hideEmpty,
  accordionState,
  onAccordionChange,
}): JSX.Element => {
  const accordionLvl4Id = acc_lvl4_prefix + index;
  return (
    <>
      <EuiAccordion
        id="id2"
        buttonContent={"["+index+"]"}
        buttonContentClassName="accordion-arrayValue"
        paddingSize="s"
        className="yfmAccordion"
        forceState={accordionState[accordionLvl4Id] ? 'open' : 'closed'}
        onToggle={(isOpen) => {
          onAccordionChange(accordionLvl4Id, !accordionState[accordionLvl4Id]);
        }}
      >
        <EuiSpacer size="s" />
        <EuiText>
          <EuiDescriptionList
            type="responsiveColumn"
            textStyle="reverse"
            listItems={getExternalMappingItems(hideEmpty, mapping)}
            descriptionProps={{ className: "eui-textBreakWord" }}
            style={{ maxWidth: '330px' }}
          />
        </EuiText>
      </EuiAccordion>
      <EuiSpacer size="s" />
    </>
  );
}
