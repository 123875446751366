import React, { FC, useState, useEffect } from 'react';
import { Attribute } from '../types';
import {
  EuiAccordion,
  EuiSpacer,
  EuiButtonGroup,
} from '@elastic/eui';
import { AttributeAccordion } from './AttributeAccordion';

interface YFMRenderType {
  selectedAttribute: Attribute
  attributes: Array<Attribute>
  hideIfEmpty: boolean
}

export const acc_lvl2_prefix = 'accordion-lvl2-';
export const acc_lvl3_prefix = 'accordion-lvl3-';
export const acc_lvl3_2_prefix = 'accordion-lvl3-2-';
export const acc_lvl4_prefix = 'accordion-lvl4-';

type InitialState = { [index: string]: boolean };

const getInitialState = (attribute: Attribute): InitialState => {
  const x = 'accordion-1';
  const initialState: { [index: string]: boolean } = {
    [x]: true,
  };
  (attribute || []).yfm.attributes.forEach((yfm: any, key: number) => {
    initialState[acc_lvl2_prefix + key] = true;
    initialState[acc_lvl2_prefix + key + "-lv"] = true;

    (yfm.listValues || []).forEach((listValue: any, key2: number) => {
      initialState[acc_lvl3_prefix + key2] = true;
      initialState[acc_lvl3_2_prefix + key2] = true;
      initialState[acc_lvl3_prefix + key + "-em"] = true;

      (listValue.externalMappings || []).forEach((value: any, key3: number) => {
        initialState[acc_lvl4_prefix + key3] = true;
      });
    });
  });
  return initialState;
}


export const YFMRender: FC<YFMRenderType> = ({
  selectedAttribute,
  attributes,
  hideIfEmpty,
}) => {
  const [state, setState] = useState<'open' | 'closed'>('open');

  const [accordions, setAccordions] = useState<InitialState>(getInitialState(selectedAttribute))
  useEffect(() => {
    setAccordions(getInitialState(selectedAttribute));
  }, [selectedAttribute, attributes]);


  const buttonGroupClick = (open: boolean) => {
    const newState: InitialState = {};
    for (const [index, [key, value]] of Object.entries(Object.entries(accordions))) {
      newState[key] = open;
    }
    setAccordions(newState);
  }

  return (
    <>
      <EuiButtonGroup
        onChange={(id: string) => {
          // id values: expandAll or collapseAll

          // expand or collapse all accordions
          buttonGroupClick(id === 'expandAll');

          // Change state of button group
          const newState = id === 'expandAll' ? 'open' : 'closed';
          setState(newState);
        }}
        buttonSize="compressed"
        isIconOnly
        options={[{
          id: 'expandAll',
          label: 'Expand All',
          iconType: 'unfold',
          isDisabled: state === 'open',
        },
        {
          id: 'collapseAll',
          label: 'Collapse All',
          iconType: 'fold',
          isDisabled: state === 'closed',
        }]}
        style={{
          margin: "5px"
        }}
        type="multi"
        legend=""
      />
      <EuiSpacer size="s" />
      <EuiAccordion
        id="attriubutesAccordion"
        buttonContent="Attributes"
        paddingSize="s"
        className="yfmAccordion"
        initialIsOpen={state === 'open'}
        onToggle={(isOpen) => {
          setAccordions({
            ...accordions,
            'accordion-1': !accordions['accordion-1']
          });
        }}
        forceState={accordions['accordion-1'] ? 'open' : 'closed'}
      >
        {selectedAttribute.yfm.attributes.map(
          (yfm: any, key: number) => {
            if (yfm === null) {
              return <div />
            }

            return <AttributeAccordion
              key={key}
              selectedAttribute={selectedAttribute}
              index={key}
              hideEmpty={hideIfEmpty}
              yfm={yfm}
              accordionState={accordions}
              onAccordionChange={(accordionId, open) => {
                setAccordions({
                  ...accordions,
                  [accordionId]: open
                });
              }}
            />
          }
        )}
      </EuiAccordion>
      <EuiSpacer size="s" />
    </>
  );
}