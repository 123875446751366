import React, { FC } from 'react';
import Routes from './Routes';
import { Auth0Provider } from './auth/wrapper';
import history from "./history";
import { AUTH0 } from '../config';

const onRedirectCallback = (appState?: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

export const Auth: FC = () => {
  return <Auth0Provider
    onRedirectCallback={onRedirectCallback}
    {...AUTH0(window.location.origin)}
  >
    <Routes />
  </Auth0Provider>
}

export default Auth;
