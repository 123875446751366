import React, { FC } from 'react';
import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiDescriptionList,
} from '@elastic/eui';
import { acc_lvl4_prefix } from './YFMRender';

const nullOrEmpty = (value: any): boolean => {
  return value === null || value === '';
}

const getSoftMappingItems = (hideIfEmpty: boolean, mapping: any) => {
  const descriptions = [];
  if (!hideIfEmpty ? true : !nullOrEmpty(mapping.modelSchema))
    descriptions.push({ title: 'Model Schema', description: mapping.modelSchema });
  if (!hideIfEmpty ? true : !nullOrEmpty(mapping.secondaryModifier))
    descriptions.push({ title: 'Secondary Modifier', description: mapping.secondaryModifier });
  if (!hideIfEmpty ? true : !nullOrEmpty(mapping.value))
    descriptions.push({ title: 'Value', description: mapping.value });

  return descriptions;
}

interface ISoftMappingsAccordion {
  softMapping: any,
  index: number,
  hideEmpty: boolean,
  accordionState: { [index: string]: boolean }
  onAccordionChange: (accordionId: string, open: boolean) => void;
}

export const SoftMappingsAccordion: FC<ISoftMappingsAccordion> = ({
  softMapping,
  index,
  hideEmpty,
  accordionState,
  onAccordionChange,
}): JSX.Element => {
  const accordionLvl4Id = acc_lvl4_prefix + index;
  return (
    <>
      <EuiAccordion
        id="id2"
        buttonContent={"[" + index + "]"}
        buttonContentClassName="accordion-arrayValue"
        paddingSize="s"
        className="yfmAccordion"
        forceState={accordionState[accordionLvl4Id] ? 'open' : 'closed'}
        onToggle={(isOpen) => {
          onAccordionChange(accordionLvl4Id, !accordionState[accordionLvl4Id]);
        }}
      >
        <EuiSpacer size="s" />
        <EuiText>
          <EuiDescriptionList
            type="responsiveColumn"
            textStyle="reverse"
            listItems={getSoftMappingItems(hideEmpty, softMapping)}
            descriptionProps={{ className: "eui-textBreakWord" }}
            style={{ maxWidth: '330px' }}
          />
        </EuiText>
      </EuiAccordion>
      <EuiSpacer size="s" />
    </>
  );
}
