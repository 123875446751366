import React, { FC } from "react";
import { useAuth0 } from "../auth/hooks";
import LoadingPage from "./LoadingPage";


export const LogoutPage: FC = () => {
  const { logout } = useAuth0();
  if (logout) {
    logout({
      returnTo: window.location.origin,
    });
  }
  return <LoadingPage />
}

export default LogoutPage;