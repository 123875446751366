import React, { FC } from 'react';
import { Attribute } from '../types';
import { 
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiDescriptionList,
} from '@elastic/eui';
import { ListValueAccordion } from './ListValueAccordion';
import { acc_lvl2_prefix } from './YFMRender';
import { SecondaryModifierAccordion } from './SecondaryModifier';

export const getAttributeItems = (hideIfEmpty: boolean, selectedAttribute: Attribute, rawAttribute: any) => {
  const descriptions = [
    {
      title: 'File',
      description: selectedAttribute.filename,
    },
    {
      title: 'API Name',
      description: rawAttribute.apiName,
    },
    {
      title: 'DB Name',
      description: rawAttribute.dbName,
    },
  ];
  if (!hideIfEmpty ? true : rawAttribute.defaultHeaderProvenance !== null)
    descriptions.push({title: 'Default Header Provenance', description: rawAttribute.defaultHeaderProvenance || ''});
  
  if (!hideIfEmpty ? true : rawAttribute.derived !== null)
    descriptions.push({title: 'Derived', description: rawAttribute.derived ? 'true' : 'false'});
  
  if (!hideIfEmpty ? true : rawAttribute.displayName !== null)
    descriptions.push({title: 'Display Name', description: rawAttribute.displayName || ''});
  
  if (!hideIfEmpty ? true : rawAttribute.displayWidth !== null)
    descriptions.push({title: 'Display Width', description: rawAttribute.displayWidth || ''});
  
  if (!hideIfEmpty ? true : rawAttribute.exportPriority !== null)
    descriptions.push({title: 'Export Priority', description: rawAttribute.exportPriority || ''});
  
  if (!hideIfEmpty ? true : rawAttribute.gridWidth !== null)
    descriptions.push({title: 'Grid Width', description: rawAttribute.gridWidth || ''});

  if (!hideIfEmpty ? true : rawAttribute.group !== null)
    descriptions.push({title: 'Group', description: rawAttribute.group || ''});

  if (!hideIfEmpty ? true : rawAttribute.hexColorCode !== null)
    descriptions.push({title: 'Hex Color Code', description: rawAttribute.hexColorCode || ''});

  if (!hideIfEmpty ? true : rawAttribute.maxThreshold !== null)
    descriptions.push({title: 'Max Threshold', description: rawAttribute.maxThreshold || ''});

  if (!hideIfEmpty ? true : rawAttribute.module !== null)
    descriptions.push({title: 'Module', description: rawAttribute.module || ''});

  if (!hideIfEmpty ? true : rawAttribute.parent !== null)
    descriptions.push({title: 'Parent', description: rawAttribute.parent || ''});

  if (!hideIfEmpty ? true : rawAttribute.position !== null)
    descriptions.push({title: 'Position', description: rawAttribute.position || ''});

  if (!hideIfEmpty ? true : rawAttribute.presentationType !== null)
    descriptions.push({title: 'Presentation Type', description: rawAttribute.presentationType || ''});

  if (!hideIfEmpty ? true : rawAttribute.storageType !== null)
    descriptions.push({title: 'Storage Type', description: rawAttribute.storageType || ''});

  if (!hideIfEmpty ? true : rawAttribute.subgroup !== null)
    descriptions.push({title: 'Subgroup', description: rawAttribute.subgroup || ''});

  return descriptions;
}

interface IAttributeAccordion {
  selectedAttribute: Attribute;
  index: number;
  hideEmpty: boolean;
  yfm: any;
  accordionState: {[index:string]:boolean}
  onAccordionChange: (accordionId: string, open: boolean) => void;
}

export const AttributeAccordion: FC<IAttributeAccordion> = ({
  selectedAttribute,
  index,
  hideEmpty,
  yfm,
  accordionState,
  onAccordionChange,
}): JSX.Element => {
  const accordionLvl2Id = acc_lvl2_prefix + index;
  const accLvl2IdListValue = accordionLvl2Id + '-lv'

  return (
    <>
      <EuiAccordion
        id="id2"
        buttonContent={"["+index+"]"}
        buttonContentClassName="accordion-arrayValue"
        paddingSize="s"
        className="yfmAccordion"
        forceState={accordionState[accordionLvl2Id] ? 'open' : 'closed'}
        onToggle={(isOpen) => {
          onAccordionChange(accordionLvl2Id, !accordionState[accordionLvl2Id]);
        }}
      >
        <EuiText>
          <EuiDescriptionList
            type="responsiveColumn"
            textStyle="reverse"
            listItems={getAttributeItems(hideEmpty, selectedAttribute, yfm)}
            descriptionProps={{ className: "eui-textBreakWord" }}
            style={{ maxWidth: '330px', marginTop: '4px' }}
          />
        </EuiText>
        {(yfm.secondaryModifier || []).length > 0 && (
          <>
            <EuiSpacer size="m" />
            <SecondaryModifierAccordion
              secondaryModifier={yfm.secondaryModifier[0]}
              index={0}
              hideEmpty={hideEmpty}
              accordionState={accordionState}
              onAccordionChange={onAccordionChange}
            />
          </>
        )}
        {(yfm.listValues || []).length > 0 && (
          <>
            <EuiSpacer size="m" />
            <EuiAccordion
              id="id2"
              buttonContent="List Values"
              paddingSize="s"
              className="yfmAccordion"
              forceState={accordionState[accLvl2IdListValue] ? 'open' : 'closed'}
              onToggle={(isOpen) => {
                onAccordionChange(accLvl2IdListValue, !accordionState[accLvl2IdListValue]);
              }}
            >
              {(yfm.listValues || []).map((listValue: any, listValAccKey: number) => {
                return <ListValueAccordion
                  key={listValAccKey}
                  listValue={listValue}
                  index={listValAccKey}
                  hideEmpty={hideEmpty}
                  accordionState={accordionState}
                  onAccordionChange={onAccordionChange}
                />;
              })} 
            </EuiAccordion>
          </>
        )}
      </EuiAccordion>
    </>
  )
}