import React, { FC, useState, useEffect } from 'react';
import { attributeMap as propertyAttributeMap } from '../gen/property/attributeMap';
import { attributeMap as programAttributeMap } from '../gen/program/attributeMap';
import { attributeMap as policyAttributeMap } from '../gen/policy/attributeMap';
import { attributeMap as lossAttributeMap } from '../gen/loss/attributeMap';
import { Search } from './Search/Search';
import { MarkdownWrap } from './MarkdownWrap/MarkdownWrap';
import { YFMRender } from './YFMRender/YFMRender';
import { Attribute } from './types';
import archipelagoLogo from './arch_green.png';
import { EuiButtonGroup, EuiTitle } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import HeaderUserMenu from './HeaderUserMenu';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { useHistory } from 'react-router-dom';
import { exortToPDF } from './export';
import mixpanel from 'mixpanel-browser';

export type EntityValues = 'property' | 'program' | 'policy' | 'loss';

const FILENAME = 'archipelago-schema-documentation.pdf';

interface IProps {
  decryptKey: string;
  entity: EntityValues;
}

const getPath = (pathname: string) => {
  const arr = pathname.split('/');
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== '') {
      return arr[i];
    }
  }
  return null;
};

const loadAttributes = (decryptKey: string, entity: string) => {
  let arr: Array<Attribute> = [];
  let x: any;
  if (entity === 'property') {
    x = propertyAttributeMap;
  } else if (entity === 'program') {
    x = programAttributeMap;
  } else if (entity === 'policy') {
    x = policyAttributeMap;
  } else if (entity === 'loss') {
    x = lossAttributeMap;
  }

  (x || []).forEach(
    (value: { f: string; m: string; y: string }, index: number) => {
      const filename = CryptoAES.decrypt(value.f, decryptKey).toString(
        CryptoENC
      );
      const markdown = CryptoAES.decrypt(value.m, decryptKey).toString(
        CryptoENC
      );
      const yfm = JSON.parse(
        CryptoAES.decrypt(value.y, decryptKey).toString(CryptoENC)
      );

      for (let i = 0; i < yfm.attributes.length; i++) {
        const a = yfm.attributes[i];
        arr.push({
          filename: filename,
          displayName: a.displayName,
          attributeName: a.apiName,
          markdown: markdown,
          yfm: yfm,
        });
      }
    }
  );
  arr.sort((a, b) => {
    return a.displayName.localeCompare(b.displayName, 'en');
  });
  return arr;
};

export const AttributeManager: FC<IProps> = ({ decryptKey, entity }) => {
  let redirectMap = new Map();
  redirectMap.set(
    'acquisitionOrConstructionDate',
    'acquisitionOrOccupancyDate'
  );
  redirectMap.set('aggregationTag', 'region');
  redirectMap.set('backupGeneratorInspection', 'generatorTestingProgram');
  redirectMap.set('buildingAppendages', 'ornamentation');
  redirectMap.set('buildingFootprint', 'buildingFootprintClass');
  redirectMap.set('buildingHistory', 'buildingDescription');
  redirectMap.set('buildings', 'buildingReplacementCost');
  redirectMap.set('buildingsDisplay', 'buildingReplacementCostDisplay');
  redirectMap.set('builtBy', 'acquiredOrBuilt');
  redirectMap.set(
    'businessInterruptionInsuredValue',
    'businessInterruptionCost'
  );
  redirectMap.set(
    'businessInterruptionInsuredValueDisplay',
    'businessInterruptionCostDisplay'
  );
  redirectMap.set('contentInsuredValue', 'contentsReplacementCost');
  redirectMap.set(
    'contentInsuredValueDisplay',
    'contentsReplacementCostDisplay'
  );
  redirectMap.set('floodMissles', 'floodMissiles');
  redirectMap.set('hailHazard', 'hailHazardClass');
  redirectMap.set('hazards', 'hazardSummary');
  redirectMap.set(
    'highPriorityEngineeringRecommendations',
    'numberOfHighPriorityRecommendations'
  );
  redirectMap.set('lastEngineeringVisit', 'lastEngineeringVisitDate');
  redirectMap.set('lightningHazard', 'lightningHazardClass');
  redirectMap.set('managedBy', 'propertyManager');
  redirectMap.set('modelling', 'modelingAvailable');
  redirectMap.set('nrOfBuildings', 'numberOfBuildings');
  redirectMap.set('nrOfStories', 'numberOfStoriesAboveGround');
  redirectMap.set('quakeZone', 'seismicZone');
  redirectMap.set(
    'remoteMonitoringOfFireProtectionSystem',
    'remoteMonitoringOfSprinklerSystem'
  );
  redirectMap.set('roofChimneys', 'roofChimneysAnchorage');
  redirectMap.set('roofDetails', 'roofInspectionProgram');
  redirectMap.set('seismicHazard', 'seismicHazardClass');
  redirectMap.set('siteName', 'propertyName');
  redirectMap.set('sprinklers', 'buildingSprinklered');
  redirectMap.set('sprinklersSystem', 'buildingSprinklerType');
  redirectMap.set('storiesBelowGround', 'numberOfStoriesBelowGround');
  redirectMap.set('street', 'streetAddress');
  redirectMap.set('tornadoHazard', 'tornadoHazardClass');
  redirectMap.set('wildFireHazard', 'wildfireHazardClass');
  redirectMap.set('windHazard', 'windHazardClass');
  redirectMap.set('windMissles', 'windMissiles');
  redirectMap.set('yearsUnderManagement', 'yearsInPortfolio');
  redirectMap.set('zip', 'postalCode');

  const [attributes, setAttributes] = useState<{
    attributeType: EntityValues | undefined;
    attributes: Array<Attribute> | undefined;
  }>({
    attributeType: entity,
    attributes: undefined,
  });

  const [selected, setSelected] = useState<Attribute | undefined>(undefined);
  const [selectedEntity, setSelectedEntity] = useState<EntityValues>(entity);
  const history = useHistory();

  // Load the attributes
  useEffect(() => {
    let attrs = loadAttributes(decryptKey, selectedEntity.toLowerCase());
    setAttributes({
      attributeType: selectedEntity,
      attributes: attrs,
    });

    if (selectedEntity === attributes.attributeType) {
      // If there's a valid anchor in the URL then use that.
      const anchorStartPos = window?.location?.href?.indexOf('#') || -1;
      if (anchorStartPos > 0) {
        const anchor = window.location.href.substring(anchorStartPos + 1);
        if (anchor) {
          let goToAttributeName = anchor;

          // We have renamed some attributes but we want to keep the deep links intact. So we just replace them...
          const redirectTo = redirectMap.get(goToAttributeName);
          if (redirectTo) {
            goToAttributeName = redirectTo;
          }

          for (let i = 0; i < attrs.length; i++) {
            let attr = attrs[i];
            if (attr.attributeName === goToAttributeName) {
              setSelected(attr);
              return;
            }
          }
        }
      }
    }
    // else - continue regular flow

    if (attrs.length < 1) {
      return;
    }

    // If there's no anchor then add the first attribute as the anchor.
    // This also fixes wrong URL values
    const x = '/' + selectedEntity + '/#' + attrs[0].attributeName;
    history.push(x);

    // Set first attribute in array as the selected attribute.
    setSelected(attrs[0]);
  }, [decryptKey, selectedEntity, window?.location?.href]);

  // Calls setSelected and also updates the URL anchor
  const navigateToAttribute = (a: Attribute) => {
    mixpanel.track(`Schema Docs Browser: Navigate to attribute`, {
      event_surface: 'SchemaDocsBrowser',
      attributeName: a.attributeName,
      attributeDisplayName: a.displayName,
    });

    const x = '/' + entity + '/#' + a.attributeName;
    history.push(x);
    setSelected(a);
  };

  const onEntityChange = (entity: EntityValues) => {
    setSelectedEntity(entity);
  };

  if (!attributes?.attributes || !selected) {
    return <></>;
  }
  return (
    <BrowserRender
      attributes={attributes.attributes}
      selected={selected}
      entity={selectedEntity}
      onEntityChange={onEntityChange}
      navigateToAttribute={navigateToAttribute}
    />
  );
};

export const BrowserRender: FC<{
  attributes: Array<Attribute>;
  selected: Attribute;
  entity: EntityValues;
  onEntityChange: (entity: EntityValues) => void;
  navigateToAttribute: (attribute: Attribute) => void;
}> = ({
  attributes,
  selected,
  entity,
  onEntityChange,
  navigateToAttribute,
}) => {
  const [showMetadata, setShowMetadata] = useState<boolean>(true);
  const [hideIfEmpty, setHideEmptyMetadata] = useState<boolean>(true);

  const idPrefix = 'group-btn-';
  const [toggleIdToSelectedMap, setToggleIdToSelectedMap] = useState({
    [`${idPrefix}0`]: true,
    [`${idPrefix}1`]: true,
  });

  const onChangeMulti = (optionId: string) => {
    const show = !toggleIdToSelectedMap[optionId];
    // Toggle the group btn bar
    const newToggleIdToSelectedMap = {
      ...toggleIdToSelectedMap,
      ...{
        [optionId]: !toggleIdToSelectedMap[optionId],
      },
    };
    setToggleIdToSelectedMap(newToggleIdToSelectedMap);

    // Show metadata
    if (optionId === `${idPrefix}0`) {
      if (show !== showMetadata) {
        setShowMetadata(show);
      }
    }

    // Hide Empty Metadata
    if (optionId === `${idPrefix}1`) {
      setHideEmptyMetadata(show);
    }
  };

  const toggleButtonsMulti = [
    {
      id: `${idPrefix}0`,
      label: 'Show metadata',
    },
    {
      id: `${idPrefix}1`,
      label: 'Hide empty metadata',
      isDisabled: !toggleIdToSelectedMap[`${idPrefix}0`],
    },
  ];

  if (attributes === undefined || selected === undefined) {
    return <></>;
  }
  return (
    <div className="full">
      <div className="app">
        <div className="header">
          <div className="title">
            <img alt="Archipelago" src={archipelagoLogo} />
            <EuiTitle size="m">
              <h1>Schema Docs</h1>
            </EuiTitle>
          </div>
          <div>
            <EuiButtonGroup
              style={{ marginRight: '5px' }}
              legend="This is a primary group"
              options={[
                {
                  id: `export-schema-docs-0`,
                  label: 'Export Schema Docs',
                  isDisabled: false,
                },
              ]}
              idToSelectedMap={toggleIdToSelectedMap}
              onChange={(id: string) => {
                if (id === 'export-schema-docs-0') {
                  exortToPDF(attributes).then((pdf) => {
                    // pdf.save(FILENAME);
                    pdf.output('dataurlnewwindow', { filename: FILENAME });
                  });
                }
              }}
              color="secondary"
              type="multi"
            />
            <EuiButtonGroup
              legend="This is a primary group"
              options={toggleButtonsMulti}
              idToSelectedMap={toggleIdToSelectedMap}
              onChange={(id: string) => onChangeMulti(id)}
              color="primary"
              type="multi"
            />
            <HeaderUserMenu />
          </div>
        </div>
        <div className="flex-one height-overflow">
          <div className="full flex">
            <Search
              selectedAttribute={selected}
              attributes={attributes}
              entity={entity}
              onClick={navigateToAttribute}
              onEntityChange={onEntityChange}
            />
            <div className="markdown-scroll-container">
              <div className="markdown-wrapper">
                <MarkdownWrap attribute={selected} />
              </div>
            </div>
            {showMetadata && (
              <div className="right">
                <YFMRender
                  selectedAttribute={selected}
                  attributes={attributes}
                  hideIfEmpty={hideIfEmpty}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Browser: FC<{
  decryptKey: string;
}> = ({ decryptKey }) => {
  const [urlEntity, setUrlEntity] = useState<EntityValues | undefined>(
    undefined
  );

  useEffect(() => {
    const p = getPath(window?.location?.pathname);
    if (p === '' || p == null) {
      window.location.href =
        window.location.origin + '/property/' + window.location.hash;
      return;
    }
    if (p !== urlEntity) {
      setUrlEntity(p as any);
    }
  });

  if (urlEntity === undefined) {
    return null;
  }
  return <AttributeManager decryptKey={decryptKey} entity={urlEntity} />;
};

export default Browser;
