import { EuiButton, EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

export const NoAccessPage: FC = () => {
  const history = useHistory();

  return <EuiPage style={{ height: "100vh" }}>
  <EuiPageBody>
    <EuiPageContent verticalPosition="center" horizontalPosition="center">
      <EuiPageContentHeader>
        <EuiPageContentHeaderSection>
          <EuiTitle>
            <h2>Insufficient permissions</h2>
          </EuiTitle>
        </EuiPageContentHeaderSection>
      </EuiPageContentHeader>
      <EuiPageContentBody>
        
        <EuiText>
          <p>You don't have permission to access the schema documentation. Please contact Archipelago.</p>
        </EuiText>

        <EuiSpacer size="xs" />

        <EuiButton fill onClick={() => {
          history.push('/logout')
        }}>
          Log out
        </EuiButton>
        
      </EuiPageContentBody>
    </EuiPageContent>
  </EuiPageBody>
</EuiPage>
}

export default NoAccessPage;