import { Auth0ClientOptions } from "@auth0/auth0-spa-js";

// DONT EDIT - This value is maintained by the build script.
export const API_BASE_URL = 'https://platform.onarchipelago.com/api';

/* Mixpanel */
export const MIXPANEL_TOKEN = '8493234cb660c355d4b0d09bbe61e7cc';
export const MIXPANEL_PROXY_URL = 'https://anp.onarchipelago.com';

/* Auth0 */
export const AUTH0 = (origin: string): Auth0ClientOptions => ({
  client_id: '49AXJfp7tXhyhLLVR1Aw0ek2x4TScwqi',
  domain: 'auth.onarchipelago.com',
  audience: 'https://archipelago-analytics.auth0.com/userinfo',
  redirect_uri: `${origin}/callback`,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  scope: 'openid profile email',
});