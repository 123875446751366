
import { jsPDF } from "jspdf";
import { Attribute } from "./types";
import { compiler } from 'markdown-to-jsx';
import logo from './arch_gray.png';

const TITLE = "Schema Documentation"
const H1_FONT_SIZE = 16;
const H2_FONT_SIZE = 14;
const P_FONT_SIZE = 11;
const SPACER_S = 5;
const SPACER_M = 10;

export async function exortToPDF(attributes: Attribute[]): Promise<jsPDF> {
  let doc = new jsPDF();
  doc.setProperties({
    title: TITLE,
    author: 'Archipelago Analytics',
  });

  // doc.setFont("Arial");
  // doc.addFont('ArialMS', 'Arial', 'normal');
  doc.setFont('Helvetica');

  await addFirstPage(doc);

  for (let i = 0; i < attributes.length; i++) {
    const attr = attributes[i];
    // exclude air and rms attributes from export
    if (attr.attributeName.startsWith('air')) {
      continue;
    }
    if (attr.attributeName.startsWith('rms')) {
      continue;
    }

    let compilerResult = compiler(attr.markdown);

    for (let a = 0; a < attr.yfm.attributes.length; a++) {
      const x = attr.yfm.attributes[a];
      if (attr.attributeName !== x.apiName) {
        continue;
      }

      const displayName = x.displayName;

      const p = doc.addPage()
      let offset = 35;

      // Set h1
      p.setFontSize(H1_FONT_SIZE);
      p.text(displayName, 10, 25);

      // Set defintion h2 and p
      p.setFontSize(H2_FONT_SIZE);
      p.text("Definition", 10, offset);
      offset += SPACER_S;

      let foundIndex = markdownHeaderByID(compilerResult.props.children, 'definition')
      if (foundIndex > 0) {
        let text = firstParagraphAfterIndex(compilerResult.props.children, foundIndex);
        if (text) {
          p.setFontSize(P_FONT_SIZE);
          let splitTitle = p.splitTextToSize(text, 180);
          p.text(splitTitle, 10, offset);
          const d = p.getTextDimensions(splitTitle, {
            fontSize: P_FONT_SIZE,
          })
          offset += d.h + SPACER_M;
        } else {
          // To have some space between headers if <p> is not present
          offset += SPACER_M;
        }
      } else {
        offset += SPACER_M;
      }

      // Set Purpose h2 and p
      p.setFontSize(H2_FONT_SIZE);
      p.text("Purpose", 10, offset);
      offset += SPACER_S;

      foundIndex = markdownHeaderByID(compilerResult.props.children, 'purpose')
      if (foundIndex > 0) {
        let text = firstParagraphAfterIndex(compilerResult.props.children, foundIndex);
        if (text) {
          p.setFontSize(P_FONT_SIZE);
          let splitTitle = p.splitTextToSize(text, 180);
          p.text(splitTitle, 10, offset);
          const d = p.getTextDimensions(splitTitle, {
            fontSize: P_FONT_SIZE,
          })
          offset += d.h + SPACER_M;
        } else {
          offset += SPACER_M;
        }
      } else {
        offset += SPACER_M;
      }

      // Set Purpose h2 and p
      p.setFontSize(H2_FONT_SIZE);
      p.text("Example", 10, offset);
      offset += SPACER_S;
      foundIndex = markdownHeaderByID(compilerResult.props.children, 'example')
      if (foundIndex > 0) {
        let text = firstParagraphAfterIndex(compilerResult.props.children, foundIndex);
        if (text) {
          p.setFontSize(P_FONT_SIZE);
          let splitTitle = p.splitTextToSize(text, 180);
          p.text(splitTitle, 10, offset);
          const d = p.getTextDimensions(splitTitle, {
            fontSize: P_FONT_SIZE,
          })
          offset += d.h + SPACER_M;
        }
      }

      addHeader(p);
      addFooter(p);
    }
  }

  return doc;
}

function markdownHeaderByID(data: any[], headerId: string): number {
  if (!data) {
    return -1;
  }
  for (let j = 0; j < data.length; j++) {
    const c = data[j];
    if (c?.props?.id === headerId) {
      return j;
    }
  }
  return -1;
}

function firstParagraphAfterIndex(data: any[], index: number) {
  const definitionDate = data[index + 1];
  if (definitionDate.type === 'p' && definitionDate?.props) {
    const t = definitionDate.props.children[0];
    return t
  }
  return undefined
}

async function addFirstPage(pdf: jsPDF) {
  // Add company logo
  // Dimension 2000 × 285  
  let ratio = 285 / 2000;
  pdf.addImage(logo, 'png', 30, 80, 150, (150 * ratio));

  // Add title
  pdf.setFontSize(26);
  centerText(pdf, TITLE, 150);

  // Add headers an footers
  addHeader(pdf);
  addFooter(pdf);
}

function addHeader(pdf: jsPDF) {
  pdf.setFontSize(12);
  pdf.setTextColor('FF0000');
  pdf.setFont('Helvetica', 'Bold');
  pdf.text("DO NOT SHARE", 150, 13);
  pdf.setTextColor("#000000");
  pdf.setFont('Helvetica', 'normal');
}

function addFooter(pdf: jsPDF) {
  pdf.setFontSize(12);
  pdf.setTextColor('#8E8E8E');
  centerText(pdf, "© Copyright 2021 Archipelago Analytics, Inc", pdf.internal.pageSize.height - 15);
  centerText(pdf, "All rights reserved. Trademarks are the property of their respective owners", pdf.internal.pageSize.height - 10);
  pdf.setTextColor('#000000');
}

function centerText(pdf: jsPDF, txt: string, y: number) {
  const fontSize = pdf.getFontSize();
  const pageWidth = pdf.internal.pageSize.width;

  // Get the actual text's width
  /* You multiply the unit width of your string by your font size and divide
    * by the internal scale factor. The division is necessary
    * for the case where you use units other than 'pt' in the constructor
    * of jsPDF.
  */
  const unit = pdf.getStringUnitWidth(txt);
  const scaleFactor = pdf.internal.scaleFactor
  const txtWidth = unit * fontSize / scaleFactor;

  const x = (pageWidth - txtWidth) / 2;

  pdf.text(txt, x, y);
}