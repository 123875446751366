import React, { useState, useEffect, useContext } from "react";
import createAuth0Client, { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js";

export interface ContextValue {
  isAuthenticated?: boolean;
  user?: any;
  loading?: boolean;
  popupOpen?: boolean;
  loginWithPopup?: () => void;
  handleRedirectCallback?: () => void;
  getIdTokenClaims?: (...p: any) => void;
  loginWithRedirect?: (...p: any) => void;
  getTokenSilently?: (...p: any) => Promise<any> | undefined;
  getTokenWithPopup?: (...p: any) => void;
  logout?: (...p: any) => void;
}

interface Auth0ProviderProps extends Auth0ClientOptions {
  onRedirectCallback?: (appState: any) => void;
  children: React.ReactNode;
  initOptions?: Auth0ClientOptions;
}

const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<ContextValue>(
  {} as ContextValue
);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      let auth0FromHook;
      try {
        auth0FromHook = await createAuth0Client(initOptions);
      } catch (e) {
        console.error(e);
        // Scenario: refresh_token invalid or expired.
        // Shameless copy from: https://github.com/auth0/auth0-spa-js/issues/449#issuecomment-624192153
        // Something has gone wrong when the SDK has attempted to create an
        // Auth0 client and have it set up the correct authentication status for
        // the user. In this bad state, there's not much we can do but force a
        // log out on the user so that they can log in again.
        auth0FromHook = new Auth0Client(initOptions);
        auth0FromHook.logout({
          returnTo: window.location.origin,
        });
        return;
      }

      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async () => {
    setPopupOpen(true);
    try {
      if (auth0Client) {
        await auth0Client.loginWithPopup();
      } else {
        throw Error("auth0client undefined")
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    if (auth0Client) {
      const user = await auth0Client.getUser();
      setUser(user);
      setIsAuthenticated(true);
    }
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    if (auth0Client) {
      await auth0Client.handleRedirectCallback();
      const user = await auth0Client.getUser();
      setLoading(false);
      setIsAuthenticated(true);
      setUser(user);
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => auth0Client ? auth0Client.getIdTokenClaims(...p) : undefined,
        loginWithRedirect: (...p: any) => auth0Client ? auth0Client.loginWithRedirect(...p) : undefined,
        getTokenSilently: (...p: any) => auth0Client ? auth0Client.getTokenSilently(...p) : undefined,
        getTokenWithPopup: (...p: any) => auth0Client ? auth0Client.getTokenWithPopup(...p) : undefined,
        logout: (...p: any) => auth0Client ?auth0Client.logout(...p) : undefined
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
