import React, { FC } from "react";
import { 
  EuiButton,
  EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiSpacer, EuiText, EuiTitle,
} from '@elastic/eui';
import { API_BASE_URL } from "../../config";

interface IProps {
  loginWithRedirect?: (...p: any) => void;
}

export const LoginPage: FC<IProps> = ({
  loginWithRedirect,
}) => {
  return <EuiPage style={{ height: "100vh" }}>
    <EuiPageBody>
      <EuiPageContent verticalPosition="center" horizontalPosition="center">
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h2>Authentication required</h2>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          
          <EuiText>
            <p>Please log in to continue</p>
          </EuiText>

          <EuiSpacer size="xs" />

          <EuiButton fill onClick={() => {
            if (loginWithRedirect) {
              loginWithRedirect({
                apiURL: API_BASE_URL,
              });
            }
          }}>
            Log in
          </EuiButton>

        </EuiPageContentBody>
      </EuiPageContent>
    </EuiPageBody>
  </EuiPage>
}

export default LoginPage;