import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Auth from './components/Auth';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_PROXY_URL, MIXPANEL_TOKEN } from './config';

mixpanel.init(MIXPANEL_TOKEN, {
  api_host: MIXPANEL_PROXY_URL,
  persistence: 'localStorage',
});

ReactDOM.render(
  <React.StrictMode>
    <Auth />
  </React.StrictMode>,
  document.getElementById('root')
);
