import React, { FC } from 'react';
import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiDescriptionList,
} from '@elastic/eui';
import { ExternalMappingsAccordion } from './ExternalMappingsAccordion';
import { acc_lvl3_prefix } from './YFMRender';
import { SoftMappingsAccordion } from './SoftMappingsAccordion';

const nullOrEmpty = (value: any): boolean => {
  return value === null || value === undefined || value === '';
}

const getListValueItems = (hideIfEmpty: boolean, listValue: any) => {
  const descriptions = [
    {
      title: 'Value Id',
      description: listValue.valueId,
    },
    {
      title: 'Value',
      description: listValue.value,
    },
  ];
  if (!hideIfEmpty ? true : !nullOrEmpty(listValue.valueDisplay))
    descriptions.push({ title: 'Value Display', description: listValue.valueDisplay });
  if (!hideIfEmpty ? true : !nullOrEmpty(listValue.position))
    descriptions.push({ title: 'Position', description: listValue.position });
  if (!hideIfEmpty ? true : !nullOrEmpty(listValue.hexColorCode))
    descriptions.push({ title: 'Hex Color Code', description: listValue.hexColorCode });
  if (!hideIfEmpty ? true : !nullOrEmpty(listValue.clusterValue))
    descriptions.push({ title: 'Cluster Value', description: listValue.clusterValue });
  return descriptions;
}

interface IListValueAccordion {
  listValue: any;
  index: number;
  hideEmpty: boolean;
  accordionState: { [index: string]: boolean }
  onAccordionChange: (accordionId: string, open: boolean) => void;
}

export const ListValueAccordion: FC<IListValueAccordion> = ({
  index,
  listValue,
  hideEmpty,
  accordionState,
  onAccordionChange,
}): JSX.Element => {
  const accordionLvl3Id = acc_lvl3_prefix + index;
  const accLvl3ExternalMapingId = accordionLvl3Id + '-em'

  return (
    <>
      <EuiAccordion
        id="id2"
        buttonContent={"[" + index + "]"}
        buttonContentClassName="accordion-arrayValue"
        paddingSize="s"
        className="yfmAccordion"
        forceState={accordionState[accordionLvl3Id] ? 'open' : 'closed'}
        onToggle={(isOpen) => {
          onAccordionChange(accordionLvl3Id, !accordionState[accordionLvl3Id]);
        }}
      >
        <EuiText>
          <EuiDescriptionList
            type="responsiveColumn"
            textStyle="reverse"
            listItems={getListValueItems(hideEmpty, listValue)}
            descriptionProps={{ className: "eui-textBreakWord" }}
            style={{ maxWidth: '330px' }}
          />
        </EuiText>
        {(listValue.externalMappings || []).length > 0 && (
          <>
            <EuiSpacer size="m" />
            <EuiAccordion
              id="id2"
              buttonContent="External Mappings"
              paddingSize="s"
              className="yfmAccordion"
              forceState={accordionState[accLvl3ExternalMapingId] ? 'open' : 'closed'}
              onToggle={(isOpen) => {
                onAccordionChange(accLvl3ExternalMapingId, !accordionState[accLvl3ExternalMapingId]);
              }}
            >
              {(listValue.externalMappings || []).map((value: any, k: number) => {
                return <ExternalMappingsAccordion
                  key={k}
                  index={k}
                  mapping={value}
                  hideEmpty={hideEmpty}
                  accordionState={accordionState}
                  onAccordionChange={onAccordionChange}
                />;
              })}
            </EuiAccordion>
          </>
        )}
        {(listValue.softMappings || []).length > 0 && (
          <>
            <EuiSpacer size="m" />
            <EuiAccordion
              id="id2"
              buttonContent="Soft Mappings"
              paddingSize="s"
              className="yfmAccordion"
              forceState={accordionState[accLvl3ExternalMapingId] ? 'open' : 'closed'}
              onToggle={(isOpen) => {
                onAccordionChange(accLvl3ExternalMapingId, !accordionState[accLvl3ExternalMapingId]);
              }}
            >
              {(listValue.softMappings || []).map((value: any, k: number) => {
                return <SoftMappingsAccordion
                  key={k}
                  index={k}
                  softMapping={value}
                  hideEmpty={hideEmpty}
                  accordionState={accordionState}
                  onAccordionChange={onAccordionChange}
                />;
              })}
            </EuiAccordion>
          </>
        )}
      </EuiAccordion>
      <EuiSpacer size="s" />
    </>
  );
}