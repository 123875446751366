import img0 from './images/BackupGenerator/image1.png';
import img1 from './images/BackupGenerator/image2.png';
import img2 from './images/BackupGenerator/image3.png';
import img3 from './images/BackupGenerator/image4.png';
import img4 from './images/BackupGeneratorDieselStorageCapacity/image1.png';
import img5 from './images/BackupGeneratorDieselStorageCapacity/image2.png';
import img6 from './images/BackupGeneratorDieselStorageCapacity/image3.png';
import img7 from './images/BackupGeneratorLocation/image1.png';
import img8 from './images/BackupGeneratorLocation/image2.png';
import img9 from './images/BackupGeneratorLocation/image3.png';
import img10 from './images/BackupGeneratorLocation/image4.png';
import img11 from './images/BackupGeneratorLocation/image5.png';
import img12 from './images/BaseFloodElevation/image1.png';
import img13 from './images/BaseFloodElevation/image2.png';
import img14 from './images/BuildingDescription/image1.png';
import img15 from './images/BuildingDescription/image2.png';
import img16 from './images/BuildingDescription/image3.png';
import img17 from './images/BuildingFootprintClass/Circular.png';
import img18 from './images/BuildingFootprintClass/Complex.png';
import img19 from './images/BuildingFootprintClass/Example1.png';
import img20 from './images/BuildingFootprintClass/Example2.png';
import img21 from './images/BuildingFootprintClass/Example3.png';
import img22 from './images/BuildingFootprintClass/H-shaped.png';
import img23 from './images/BuildingFootprintClass/L-shaped.png';
import img24 from './images/BuildingFootprintClass/Rectangle.png';
import img25 from './images/BuildingFootprintClass/Square.png';
import img26 from './images/BuildingFootprintClass/T-shaped.png';
import img27 from './images/BuildingFootprintClass/U-shaped.png';
import img28 from './images/BuildingFoundation/Example1.png';
import img29 from './images/BuildingName/image1.png';
import img30 from './images/BuildingSprinklerType/image1.png';
import img31 from './images/BuildingSprinklerType/image2.png';
import img32 from './images/BuildingSprinklered/image1.png';
import img33 from './images/BuildingSprinklered/image2.png';
import img34 from './images/CladdingWallSystem/ArchitecturalMetalPanels.png';
import img35 from './images/CladdingWallSystem/ArchitecturalPrecastConcretePanels.png';
import img36 from './images/CladdingWallSystem/BrickVeneerOverWoodOrColdFormedSteelStudWalls.png';
import img37 from './images/CladdingWallSystem/Cast-in-placeConcreteWalls.png';
import img38 from './images/CladdingWallSystem/Example1.png';
import img39 from './images/CladdingWallSystem/Example2.png';
import img40 from './images/CladdingWallSystem/LightMetalSiding.png';
import img41 from './images/CladdingWallSystem/Metal.png';
import img42 from './images/CladdingWallSystem/PrecastConcreteWalls.png';
import img43 from './images/CladdingWallSystem/ReinforcedMasonryWalls.png';
import img44 from './images/CladdingWallSystem/Stone.png';
import img45 from './images/CladdingWallSystem/StuccoOverCMUWalls.png';
import img46 from './images/CladdingWallSystem/StuccoOverWoodOrColdFormedSteelStudWalls.png';
import img47 from './images/CladdingWallSystem/ThinStoneVeneerPanels.png';
import img48 from './images/ConstructionClass/image1.png';
import img49 from './images/ConstructionClass/image2.png';
import img50 from './images/ConstructionClass/image3.png';
import img51 from './images/ConstructionClass/image4.png';
import img52 from './images/ConstructionClass/image5.png';
import img53 from './images/ConstructionClass/image6.png';
import img54 from './images/ConstructionClass/image7.png';
import img55 from './images/ConstructionClassID/image1.png';
import img56 from './images/ConstructionClassID/image2.png';
import img57 from './images/ConstructionDescription/image1.png';
import img58 from './images/ConstructionDescription/image2.png';
import img59 from './images/ConstructionDescription/image3.png';
import img60 from './images/ConstructionDescription/image4.png';
import img61 from './images/ConstructionQuality/image1.png';
import img62 from './images/DistanceToCoast/image1.png';
import img63 from './images/DistanceToCoast/image2.png';
import img64 from './images/DistanceToNearestBuilding/image1.png';
import img65 from './images/DistanceToNearestBuilding/image2.png';
import img66 from './images/DistanceToNearestFault/image1.png';
import img67 from './images/DistanceToNearestFault/image2.png';
import img68 from './images/DistanceToNearestFault/image3.png';
import img69 from './images/DistanceToNearestFault/image4.png';
import img70 from './images/DistanceToNearestFault/image5.png';
import img71 from './images/EmergencyFloodProtectionMeasures/image1.png';
import img72 from './images/EmergencyFloodProtectionMeasures/image2.png';
import img73 from './images/FireEML-PML/image1.png';
import img74 from './images/FireEML-PML/image2.png';
import img75 from './images/FireProtectionInspectionProgram/image1.png';
import img76 from './images/FireProtectionInspectionProgram/image2.png';
import img77 from './images/FirstFloorElevation/image1.png';
import img78 from './images/FirstFloorHeight/image1.png';
import img79 from './images/FirstFloorHeight/image2.png';
import img80 from './images/FirstFloorHeight/image3.png';
import img81 from './images/FloodZone/image1.png';
import img82 from './images/FloodZone/image2.png';
import img83 from './images/FloodZone/image3.png';
import img84 from './images/FloodZone/image4.png';
import img85 from './images/FloodZone/image5.png';
import img86 from './images/FloodZone/image6.png';
import img87 from './images/GeneralSpecificATCOccupancy/image1.png';
import img88 from './images/GeneralSpecificATCOccupancy/image2.png';
import img89 from './images/GeneralSpecificATCOccupancy/image3.png';
import img90 from './images/GeneralSpecificATCOccupancy/image4.png';
import img91 from './images/GeneralSpecificATCOccupancy/image5.png';
import img92 from './images/GeneratorTestingProgram/image1.png';
import img93 from './images/GeneratorTestingProgram/image2.png';
import img94 from './images/GeoCoding/image1.png';
import img95 from './images/GeoCoding/image2.png';
import img96 from './images/GeoCoding/image3.png';
import img97 from './images/GeoCoding/image4.png';
import img98 from './images/GeoCoding/image5.png';
import img99 from './images/GeoCoding/image6.png';
import img100 from './images/GlassPercentage/image1.png';
import img101 from './images/GlassPercentage/image2.png';
import img102 from './images/GlassPercentage/image3.png';
import img103 from './images/GlassPercentage/image4.png';
import img104 from './images/GlassPercentage/image5.png';
import img105 from './images/GlassPercentage/image6.png';
import img106 from './images/GlassPercentage/image7.png';
import img107 from './images/HailHazardClass/image1.png';
import img108 from './images/HailHazardClass/image2.png';
import img109 from './images/HailHazardClass/image3.png';
import img110 from './images/HailHazardClass/image4.png';
import img111 from './images/HailHazardClass/image5.png';
import img112 from './images/HailHazardClass/image6.png';
import img113 from './images/HazardSummary/image1.png';
import img114 from './images/LandslideSusceptibility/image1.png';
import img115 from './images/LandslideSusceptibility/image2.png';
import img116 from './images/LandslideSusceptibility/image3.png';
import img117 from './images/LandslideSusceptibility/image4.png';
import img118 from './images/LandslideSusceptibility/image5.png';
import img119 from './images/LastValuationDate/vdate.png';
import img120 from './images/LightningHazardClass/image1.png';
import img121 from './images/LightningHazardClass/image2.png';
import img122 from './images/LightningHazardClass/image3.png';
import img123 from './images/LightningHazardClass/image4.png';
import img124 from './images/LightningHazardClass/image5.png';
import img125 from './images/LightningHazardClass/image6.png';
import img126 from './images/LiquefactionSusceptibility/image1.png';
import img127 from './images/LiquefactionSusceptibility/image2.png';
import img128 from './images/LiquefactionSusceptibility/image3.png';
import img129 from './images/LiquefactionSusceptibility/image4.png';
import img130 from './images/LiquefactionSusceptibility/image5.png';
import img131 from './images/LiquefactionSusceptibility/image6.png';
import img132 from './images/LiquefactionSusceptibility/image7.png';
import img133 from './images/LossEngineering/image1.png';
import img134 from './images/LossEngineering/image2.png';
import img135 from './images/LossEngineering/image3.png';
import img136 from './images/MultiTenant/image1.png';
import img137 from './images/MultiTenant/image2.png';
import img138 from './images/MultiTenant/image3.png';
import img139 from './images/MultiTenant/image4.png';
import img140 from './images/MultiTenant/image5.png';
import img141 from './images/MultiTenant/image6.png';
import img142 from './images/NaturalGasAutomaticShutoff/image1.png';
import img143 from './images/NaturalGasAutomaticShutoff/image2.png';
import img144 from './images/NaturalGasPipesSeismicSwayBracing/image1.png';
import img145 from './images/NaturalGasPipesSeismicSwayBracing/image2.png';
import img146 from './images/NumberOfBuildings/image1.png';
import img147 from './images/NumberOfBuildings/image2.png';
import img148 from './images/NumberOfBuildings/image3.png';
import img149 from './images/NumberOfBuildings/image4.png';
import img150 from './images/NumberOfBuildings/image5.png';
import img151 from './images/NumberOfBuildings/image6.png';
import img152 from './images/NumberOfHighPriorityRecommendations/image1.png';
import img153 from './images/NumberOfHighPriorityRecommendations/image2.png';
import img154 from './images/NumberOfStories/image1.png';
import img155 from './images/NumberOfStories/image2.png';
import img156 from './images/NumberOfStories/image3.png';
import img157 from './images/OccupancyDescription/image1.png';
import img158 from './images/OccupancyDescription/image2.png';
import img159 from './images/OccupancyDescription/image3.png';
import img160 from './images/OccupancyDescription/image4.png';
import img161 from './images/OccupancyDescription/image5.png';
import img162 from './images/PercentageSprinklered/image1.png';
import img163 from './images/PercentageSprinklered/image2.png';
import img164 from './images/PermanentFloodProtectionMeasures/image1.png';
import img165 from './images/PermanentFloodProtectionMeasures/image2.png';
import img166 from './images/PropertyName/image1.png';
import img167 from './images/PropertyName/image2.png';
import img168 from './images/PropertyName/image3.png';
import img169 from './images/QuakeSEL-SUL/image1.png';
import img170 from './images/QuakeSEL-SUL/image2.png';
import img171 from './images/RemoteMonitoringOfSprinklerSystem/image1.png';
import img172 from './images/RoofChimneysAnchorageBracing/image1.png';
import img173 from './images/RoofChimneysAnchorageBracing/image2.png';
import img174 from './images/RoofDeckMaterial/image1.png';
import img175 from './images/RoofDeckMaterial/image2.png';
import img176 from './images/RoofDeckMaterial/image3.png';
import img177 from './images/RoofDeckMaterial/image4.png';
import img178 from './images/RoofDrainageType/image1.png';
import img179 from './images/RoofDrainageType/image2.png';
import img180 from './images/RoofDrainageType/image3.png';
import img181 from './images/RoofDrainageType/image4.png';
import img182 from './images/RoofEquipmentAnchorageBracing/image1.png';
import img183 from './images/RoofEquipmentAnchorageBracing/image2.png';
import img184 from './images/RoofEquipmentAnchorageBracing/image3.png';
import img185 from './images/RoofGeometry/image1.png';
import img186 from './images/RoofGeometry/image2.png';
import img187 from './images/RoofGeometry/image3.png';
import img188 from './images/RoofGeometry/image4.png';
import img189 from './images/RoofGeometry/image5.png';
import img190 from './images/RoofGeometry/image6.png';
import img191 from './images/RoofImage/image1.png';
import img192 from './images/RoofImage/image2.png';
import img193 from './images/RoofImage/image3.png';
import img194 from './images/RoofInspectionProgram/image1.png';
import img195 from './images/RoofParapetsAnchorageBracing/image1.png';
import img196 from './images/RoofParapetsAnchorageBracing/image2.png';
import img197 from './images/RoofScreensAnchorageBracing/image1.png';
import img198 from './images/RoofScreensAnchorageBracing/image2.png';
import img199 from './images/RoofScreensAnchorageBracing/image3.png';
import img200 from './images/RoofSolarPanelCoverage/image1.png';
import img201 from './images/RoofSolarPanelCoverage/image2.png';
import img202 from './images/RoofSolarPanelCoverage/image3.png';
import img203 from './images/RoofSolarPanelsAnchorageBracing/image1.png';
import img204 from './images/RoofingMaterial/image1.png';
import img205 from './images/RoofingMaterial/image2.png';
import img206 from './images/RoofingMaterialAnchorage/image1.png';
import img207 from './images/RoofingMaterialAnchorage/image2.png';
import img208 from './images/RoofingMaterialAnchorage/image3.png';
import img209 from './images/RoofingMaterialInstallationYear/image1.png';
import img210 from './images/SIC-NAICS-OccupancyCode/image1.png';
import img211 from './images/SIC-NAICS-OccupancyCode/image2.png';
import img212 from './images/SIC-NAICS-OccupancyCode/image3.png';
import img213 from './images/SIC-NAICS-OccupancyCode/image4.png';
import img214 from './images/SIC-NAICS-OccupancyCode/image5.png';
import img215 from './images/SIC-NAICS-OccupancyCode/image6.png';
import img216 from './images/SeismicDesignQuality/image1.png';
import img217 from './images/SeismicDesignQuality/image2.png';
import img218 from './images/SeismicDesignR-Value/image1.png';
import img219 from './images/SeismicDesignR-Value/image2.png';
import img220 from './images/SeismicDesignR-Value/image3.png';
import img221 from './images/SeismicDesignR-Value/image4.png';
import img222 from './images/SeismicDesignR-Value/image5.png';
import img223 from './images/SeismicHazardClass/image1.png';
import img224 from './images/SeismicHazardClass/image2.png';
import img225 from './images/SeismicHazardClass/image3.png';
import img226 from './images/SeismicZone/image1.png';
import img227 from './images/SiteSoilClassification/image1.png';
import img228 from './images/SiteSoilClassification/image2.png';
import img229 from './images/SiteSoilClassification/image3.png';
import img230 from './images/SiteSoilClassification/image4.png';
import img231 from './images/SprinklerLeakDetectionSystem/image1.png';
import img232 from './images/SprinklerPipingSeismicSwayBracing/image1.png';
import img233 from './images/SprinklerPipingSeismicSwayBracing/image2.png';
import img234 from './images/SprinklerPipingSeismicSwayBracing/image3.png';
import img235 from './images/SquareFootage/image1.png';
import img236 from './images/SquareFootage/image2.png';
import img237 from './images/SquareFootage/image3.png';
import img238 from './images/SquareFootage/image4.png';
import img239 from './images/StewardshipSummary/image1.png';
import img240 from './images/StewardshipSummary/image2.png';
import img241 from './images/StructuralSystemUpgrade/image1.png';
import img242 from './images/StructuralSystemUpgrade/image2.png';
import img243 from './images/StructuralSystemUpgrade/image3.png';
import img244 from './images/SuitabilityOfFireProtectionMeasures/image1.png';
import img245 from './images/SuitabilityOfFireProtectionMeasures/image2.png';
import img246 from './images/SuitabilityOfFireProtectionMeasures/image3.png';
import img247 from './images/TornadoHazardClass/image1.png';
import img248 from './images/TornadoHazardClass/image2.png';
import img249 from './images/TornadoHazardClass/image3.png';
import img250 from './images/TornadoHazardClass/image4.png';
import img251 from './images/TornadoHazardClass/image5.png';
import img252 from './images/TornadoHazardClass/image6.png';
import img253 from './images/TsunamiHazardClass/image1.png';
import img254 from './images/TsunamiHazardClass/image2.png';
import img255 from './images/TsunamiHazardClass/image3.png';
import img256 from './images/TsunamiHazardClass/image4.png';
import img257 from './images/TsunamiHazardClass/image5.png';
import img258 from './images/TsunamiHazardClass/image6.png';
import img259 from './images/WaterSupplyFlowRate/image1.png';
import img260 from './images/WaterSupplyFlowRate/image2.png';
import img261 from './images/WildfireHazardClass/image1.png';
import img262 from './images/WildfireHazardClass/image2.png';
import img263 from './images/WildfireHazardClass/image3.png';
import img264 from './images/WildfireHazardClass/image4.png';
import img265 from './images/WildfireHazardClass/image5.png';
import img266 from './images/WildfireHazardClass/image6.png';
import img267 from './images/WindHazardClass/image1.png';
import img268 from './images/WindHazardClass/image2.png';
import img269 from './images/WindHazardClass/image3.png';
import img270 from './images/WindHazardClass/image4.png';
import img271 from './images/WindowFrameType/image1.png';
import img272 from './images/WindowFrameType/image2.png';
import img273 from './images/WindowGlazingProtection/image1.png';
import img274 from './images/WindowGlazingProtection/image2.png';
import img275 from './images/WindowGlazingProtection/image3.png';
import img276 from './images/YearBuilt/image1.png';
import img277 from './images/YearBuilt/image2.png';
import img278 from './images/YearBuilt/image3.png';
import img279 from './images/YearBuilt/image4.png';
import img280 from './images/primaryTenantNAICS/image1.png';
import img281 from './images/primaryTenantNAICS/image2.png';
import img282 from './images/primaryTenantNAICS/image3.png';
import img283 from './images/primaryTenantNAICS/image4.png';
import img284 from './images/primaryTenantNAICS/image5.png';

export const imagesMap = [
  {"key": "images/BackupGenerator/image1.png", "value": img0},
  {"key": "images/BackupGenerator/image2.png", "value": img1},
  {"key": "images/BackupGenerator/image3.png", "value": img2},
  {"key": "images/BackupGenerator/image4.png", "value": img3},
  {"key": "images/BackupGeneratorDieselStorageCapacity/image1.png", "value": img4},
  {"key": "images/BackupGeneratorDieselStorageCapacity/image2.png", "value": img5},
  {"key": "images/BackupGeneratorDieselStorageCapacity/image3.png", "value": img6},
  {"key": "images/BackupGeneratorLocation/image1.png", "value": img7},
  {"key": "images/BackupGeneratorLocation/image2.png", "value": img8},
  {"key": "images/BackupGeneratorLocation/image3.png", "value": img9},
  {"key": "images/BackupGeneratorLocation/image4.png", "value": img10},
  {"key": "images/BackupGeneratorLocation/image5.png", "value": img11},
  {"key": "images/BaseFloodElevation/image1.png", "value": img12},
  {"key": "images/BaseFloodElevation/image2.png", "value": img13},
  {"key": "images/BuildingDescription/image1.png", "value": img14},
  {"key": "images/BuildingDescription/image2.png", "value": img15},
  {"key": "images/BuildingDescription/image3.png", "value": img16},
  {"key": "images/BuildingFootprintClass/Circular.png", "value": img17},
  {"key": "images/BuildingFootprintClass/Complex.png", "value": img18},
  {"key": "images/BuildingFootprintClass/Example1.png", "value": img19},
  {"key": "images/BuildingFootprintClass/Example2.png", "value": img20},
  {"key": "images/BuildingFootprintClass/Example3.png", "value": img21},
  {"key": "images/BuildingFootprintClass/H-shaped.png", "value": img22},
  {"key": "images/BuildingFootprintClass/L-shaped.png", "value": img23},
  {"key": "images/BuildingFootprintClass/Rectangle.png", "value": img24},
  {"key": "images/BuildingFootprintClass/Square.png", "value": img25},
  {"key": "images/BuildingFootprintClass/T-shaped.png", "value": img26},
  {"key": "images/BuildingFootprintClass/U-shaped.png", "value": img27},
  {"key": "images/BuildingFoundation/Example1.png", "value": img28},
  {"key": "images/BuildingName/image1.png", "value": img29},
  {"key": "images/BuildingSprinklerType/image1.png", "value": img30},
  {"key": "images/BuildingSprinklerType/image2.png", "value": img31},
  {"key": "images/BuildingSprinklered/image1.png", "value": img32},
  {"key": "images/BuildingSprinklered/image2.png", "value": img33},
  {"key": "images/CladdingWallSystem/ArchitecturalMetalPanels.png", "value": img34},
  {"key": "images/CladdingWallSystem/ArchitecturalPrecastConcretePanels.png", "value": img35},
  {"key": "images/CladdingWallSystem/BrickVeneerOverWoodOrColdFormedSteelStudWalls.png", "value": img36},
  {"key": "images/CladdingWallSystem/Cast-in-placeConcreteWalls.png", "value": img37},
  {"key": "images/CladdingWallSystem/Example1.png", "value": img38},
  {"key": "images/CladdingWallSystem/Example2.png", "value": img39},
  {"key": "images/CladdingWallSystem/LightMetalSiding.png", "value": img40},
  {"key": "images/CladdingWallSystem/Metal.png", "value": img41},
  {"key": "images/CladdingWallSystem/PrecastConcreteWalls.png", "value": img42},
  {"key": "images/CladdingWallSystem/ReinforcedMasonryWalls.png", "value": img43},
  {"key": "images/CladdingWallSystem/Stone.png", "value": img44},
  {"key": "images/CladdingWallSystem/StuccoOverCMUWalls.png", "value": img45},
  {"key": "images/CladdingWallSystem/StuccoOverWoodOrColdFormedSteelStudWalls.png", "value": img46},
  {"key": "images/CladdingWallSystem/ThinStoneVeneerPanels.png", "value": img47},
  {"key": "images/ConstructionClass/image1.png", "value": img48},
  {"key": "images/ConstructionClass/image2.png", "value": img49},
  {"key": "images/ConstructionClass/image3.png", "value": img50},
  {"key": "images/ConstructionClass/image4.png", "value": img51},
  {"key": "images/ConstructionClass/image5.png", "value": img52},
  {"key": "images/ConstructionClass/image6.png", "value": img53},
  {"key": "images/ConstructionClass/image7.png", "value": img54},
  {"key": "images/ConstructionClassID/image1.png", "value": img55},
  {"key": "images/ConstructionClassID/image2.png", "value": img56},
  {"key": "images/ConstructionDescription/image1.png", "value": img57},
  {"key": "images/ConstructionDescription/image2.png", "value": img58},
  {"key": "images/ConstructionDescription/image3.png", "value": img59},
  {"key": "images/ConstructionDescription/image4.png", "value": img60},
  {"key": "images/ConstructionQuality/image1.png", "value": img61},
  {"key": "images/DistanceToCoast/image1.png", "value": img62},
  {"key": "images/DistanceToCoast/image2.png", "value": img63},
  {"key": "images/DistanceToNearestBuilding/image1.png", "value": img64},
  {"key": "images/DistanceToNearestBuilding/image2.png", "value": img65},
  {"key": "images/DistanceToNearestFault/image1.png", "value": img66},
  {"key": "images/DistanceToNearestFault/image2.png", "value": img67},
  {"key": "images/DistanceToNearestFault/image3.png", "value": img68},
  {"key": "images/DistanceToNearestFault/image4.png", "value": img69},
  {"key": "images/DistanceToNearestFault/image5.png", "value": img70},
  {"key": "images/EmergencyFloodProtectionMeasures/image1.png", "value": img71},
  {"key": "images/EmergencyFloodProtectionMeasures/image2.png", "value": img72},
  {"key": "images/FireEML-PML/image1.png", "value": img73},
  {"key": "images/FireEML-PML/image2.png", "value": img74},
  {"key": "images/FireProtectionInspectionProgram/image1.png", "value": img75},
  {"key": "images/FireProtectionInspectionProgram/image2.png", "value": img76},
  {"key": "images/FirstFloorElevation/image1.png", "value": img77},
  {"key": "images/FirstFloorHeight/image1.png", "value": img78},
  {"key": "images/FirstFloorHeight/image2.png", "value": img79},
  {"key": "images/FirstFloorHeight/image3.png", "value": img80},
  {"key": "images/FloodZone/image1.png", "value": img81},
  {"key": "images/FloodZone/image2.png", "value": img82},
  {"key": "images/FloodZone/image3.png", "value": img83},
  {"key": "images/FloodZone/image4.png", "value": img84},
  {"key": "images/FloodZone/image5.png", "value": img85},
  {"key": "images/FloodZone/image6.png", "value": img86},
  {"key": "images/GeneralSpecificATCOccupancy/image1.png", "value": img87},
  {"key": "images/GeneralSpecificATCOccupancy/image2.png", "value": img88},
  {"key": "images/GeneralSpecificATCOccupancy/image3.png", "value": img89},
  {"key": "images/GeneralSpecificATCOccupancy/image4.png", "value": img90},
  {"key": "images/GeneralSpecificATCOccupancy/image5.png", "value": img91},
  {"key": "images/GeneratorTestingProgram/image1.png", "value": img92},
  {"key": "images/GeneratorTestingProgram/image2.png", "value": img93},
  {"key": "images/GeoCoding/image1.png", "value": img94},
  {"key": "images/GeoCoding/image2.png", "value": img95},
  {"key": "images/GeoCoding/image3.png", "value": img96},
  {"key": "images/GeoCoding/image4.png", "value": img97},
  {"key": "images/GeoCoding/image5.png", "value": img98},
  {"key": "images/GeoCoding/image6.png", "value": img99},
  {"key": "images/GlassPercentage/image1.png", "value": img100},
  {"key": "images/GlassPercentage/image2.png", "value": img101},
  {"key": "images/GlassPercentage/image3.png", "value": img102},
  {"key": "images/GlassPercentage/image4.png", "value": img103},
  {"key": "images/GlassPercentage/image5.png", "value": img104},
  {"key": "images/GlassPercentage/image6.png", "value": img105},
  {"key": "images/GlassPercentage/image7.png", "value": img106},
  {"key": "images/HailHazardClass/image1.png", "value": img107},
  {"key": "images/HailHazardClass/image2.png", "value": img108},
  {"key": "images/HailHazardClass/image3.png", "value": img109},
  {"key": "images/HailHazardClass/image4.png", "value": img110},
  {"key": "images/HailHazardClass/image5.png", "value": img111},
  {"key": "images/HailHazardClass/image6.png", "value": img112},
  {"key": "images/HazardSummary/image1.png", "value": img113},
  {"key": "images/LandslideSusceptibility/image1.png", "value": img114},
  {"key": "images/LandslideSusceptibility/image2.png", "value": img115},
  {"key": "images/LandslideSusceptibility/image3.png", "value": img116},
  {"key": "images/LandslideSusceptibility/image4.png", "value": img117},
  {"key": "images/LandslideSusceptibility/image5.png", "value": img118},
  {"key": "images/LastValuationDate/vdate.png", "value": img119},
  {"key": "images/LightningHazardClass/image1.png", "value": img120},
  {"key": "images/LightningHazardClass/image2.png", "value": img121},
  {"key": "images/LightningHazardClass/image3.png", "value": img122},
  {"key": "images/LightningHazardClass/image4.png", "value": img123},
  {"key": "images/LightningHazardClass/image5.png", "value": img124},
  {"key": "images/LightningHazardClass/image6.png", "value": img125},
  {"key": "images/LiquefactionSusceptibility/image1.png", "value": img126},
  {"key": "images/LiquefactionSusceptibility/image2.png", "value": img127},
  {"key": "images/LiquefactionSusceptibility/image3.png", "value": img128},
  {"key": "images/LiquefactionSusceptibility/image4.png", "value": img129},
  {"key": "images/LiquefactionSusceptibility/image5.png", "value": img130},
  {"key": "images/LiquefactionSusceptibility/image6.png", "value": img131},
  {"key": "images/LiquefactionSusceptibility/image7.png", "value": img132},
  {"key": "images/LossEngineering/image1.png", "value": img133},
  {"key": "images/LossEngineering/image2.png", "value": img134},
  {"key": "images/LossEngineering/image3.png", "value": img135},
  {"key": "images/MultiTenant/image1.png", "value": img136},
  {"key": "images/MultiTenant/image2.png", "value": img137},
  {"key": "images/MultiTenant/image3.png", "value": img138},
  {"key": "images/MultiTenant/image4.png", "value": img139},
  {"key": "images/MultiTenant/image5.png", "value": img140},
  {"key": "images/MultiTenant/image6.png", "value": img141},
  {"key": "images/NaturalGasAutomaticShutoff/image1.png", "value": img142},
  {"key": "images/NaturalGasAutomaticShutoff/image2.png", "value": img143},
  {"key": "images/NaturalGasPipesSeismicSwayBracing/image1.png", "value": img144},
  {"key": "images/NaturalGasPipesSeismicSwayBracing/image2.png", "value": img145},
  {"key": "images/NumberOfBuildings/image1.png", "value": img146},
  {"key": "images/NumberOfBuildings/image2.png", "value": img147},
  {"key": "images/NumberOfBuildings/image3.png", "value": img148},
  {"key": "images/NumberOfBuildings/image4.png", "value": img149},
  {"key": "images/NumberOfBuildings/image5.png", "value": img150},
  {"key": "images/NumberOfBuildings/image6.png", "value": img151},
  {"key": "images/NumberOfHighPriorityRecommendations/image1.png", "value": img152},
  {"key": "images/NumberOfHighPriorityRecommendations/image2.png", "value": img153},
  {"key": "images/NumberOfStories/image1.png", "value": img154},
  {"key": "images/NumberOfStories/image2.png", "value": img155},
  {"key": "images/NumberOfStories/image3.png", "value": img156},
  {"key": "images/OccupancyDescription/image1.png", "value": img157},
  {"key": "images/OccupancyDescription/image2.png", "value": img158},
  {"key": "images/OccupancyDescription/image3.png", "value": img159},
  {"key": "images/OccupancyDescription/image4.png", "value": img160},
  {"key": "images/OccupancyDescription/image5.png", "value": img161},
  {"key": "images/PercentageSprinklered/image1.png", "value": img162},
  {"key": "images/PercentageSprinklered/image2.png", "value": img163},
  {"key": "images/PermanentFloodProtectionMeasures/image1.png", "value": img164},
  {"key": "images/PermanentFloodProtectionMeasures/image2.png", "value": img165},
  {"key": "images/PropertyName/image1.png", "value": img166},
  {"key": "images/PropertyName/image2.png", "value": img167},
  {"key": "images/PropertyName/image3.png", "value": img168},
  {"key": "images/QuakeSEL-SUL/image1.png", "value": img169},
  {"key": "images/QuakeSEL-SUL/image2.png", "value": img170},
  {"key": "images/RemoteMonitoringOfSprinklerSystem/image1.png", "value": img171},
  {"key": "images/RoofChimneysAnchorageBracing/image1.png", "value": img172},
  {"key": "images/RoofChimneysAnchorageBracing/image2.png", "value": img173},
  {"key": "images/RoofDeckMaterial/image1.png", "value": img174},
  {"key": "images/RoofDeckMaterial/image2.png", "value": img175},
  {"key": "images/RoofDeckMaterial/image3.png", "value": img176},
  {"key": "images/RoofDeckMaterial/image4.png", "value": img177},
  {"key": "images/RoofDrainageType/image1.png", "value": img178},
  {"key": "images/RoofDrainageType/image2.png", "value": img179},
  {"key": "images/RoofDrainageType/image3.png", "value": img180},
  {"key": "images/RoofDrainageType/image4.png", "value": img181},
  {"key": "images/RoofEquipmentAnchorageBracing/image1.png", "value": img182},
  {"key": "images/RoofEquipmentAnchorageBracing/image2.png", "value": img183},
  {"key": "images/RoofEquipmentAnchorageBracing/image3.png", "value": img184},
  {"key": "images/RoofGeometry/image1.png", "value": img185},
  {"key": "images/RoofGeometry/image2.png", "value": img186},
  {"key": "images/RoofGeometry/image3.png", "value": img187},
  {"key": "images/RoofGeometry/image4.png", "value": img188},
  {"key": "images/RoofGeometry/image5.png", "value": img189},
  {"key": "images/RoofGeometry/image6.png", "value": img190},
  {"key": "images/RoofImage/image1.png", "value": img191},
  {"key": "images/RoofImage/image2.png", "value": img192},
  {"key": "images/RoofImage/image3.png", "value": img193},
  {"key": "images/RoofInspectionProgram/image1.png", "value": img194},
  {"key": "images/RoofParapetsAnchorageBracing/image1.png", "value": img195},
  {"key": "images/RoofParapetsAnchorageBracing/image2.png", "value": img196},
  {"key": "images/RoofScreensAnchorageBracing/image1.png", "value": img197},
  {"key": "images/RoofScreensAnchorageBracing/image2.png", "value": img198},
  {"key": "images/RoofScreensAnchorageBracing/image3.png", "value": img199},
  {"key": "images/RoofSolarPanelCoverage/image1.png", "value": img200},
  {"key": "images/RoofSolarPanelCoverage/image2.png", "value": img201},
  {"key": "images/RoofSolarPanelCoverage/image3.png", "value": img202},
  {"key": "images/RoofSolarPanelsAnchorageBracing/image1.png", "value": img203},
  {"key": "images/RoofingMaterial/image1.png", "value": img204},
  {"key": "images/RoofingMaterial/image2.png", "value": img205},
  {"key": "images/RoofingMaterialAnchorage/image1.png", "value": img206},
  {"key": "images/RoofingMaterialAnchorage/image2.png", "value": img207},
  {"key": "images/RoofingMaterialAnchorage/image3.png", "value": img208},
  {"key": "images/RoofingMaterialInstallationYear/image1.png", "value": img209},
  {"key": "images/SIC-NAICS-OccupancyCode/image1.png", "value": img210},
  {"key": "images/SIC-NAICS-OccupancyCode/image2.png", "value": img211},
  {"key": "images/SIC-NAICS-OccupancyCode/image3.png", "value": img212},
  {"key": "images/SIC-NAICS-OccupancyCode/image4.png", "value": img213},
  {"key": "images/SIC-NAICS-OccupancyCode/image5.png", "value": img214},
  {"key": "images/SIC-NAICS-OccupancyCode/image6.png", "value": img215},
  {"key": "images/SeismicDesignQuality/image1.png", "value": img216},
  {"key": "images/SeismicDesignQuality/image2.png", "value": img217},
  {"key": "images/SeismicDesignR-Value/image1.png", "value": img218},
  {"key": "images/SeismicDesignR-Value/image2.png", "value": img219},
  {"key": "images/SeismicDesignR-Value/image3.png", "value": img220},
  {"key": "images/SeismicDesignR-Value/image4.png", "value": img221},
  {"key": "images/SeismicDesignR-Value/image5.png", "value": img222},
  {"key": "images/SeismicHazardClass/image1.png", "value": img223},
  {"key": "images/SeismicHazardClass/image2.png", "value": img224},
  {"key": "images/SeismicHazardClass/image3.png", "value": img225},
  {"key": "images/SeismicZone/image1.png", "value": img226},
  {"key": "images/SiteSoilClassification/image1.png", "value": img227},
  {"key": "images/SiteSoilClassification/image2.png", "value": img228},
  {"key": "images/SiteSoilClassification/image3.png", "value": img229},
  {"key": "images/SiteSoilClassification/image4.png", "value": img230},
  {"key": "images/SprinklerLeakDetectionSystem/image1.png", "value": img231},
  {"key": "images/SprinklerPipingSeismicSwayBracing/image1.png", "value": img232},
  {"key": "images/SprinklerPipingSeismicSwayBracing/image2.png", "value": img233},
  {"key": "images/SprinklerPipingSeismicSwayBracing/image3.png", "value": img234},
  {"key": "images/SquareFootage/image1.png", "value": img235},
  {"key": "images/SquareFootage/image2.png", "value": img236},
  {"key": "images/SquareFootage/image3.png", "value": img237},
  {"key": "images/SquareFootage/image4.png", "value": img238},
  {"key": "images/StewardshipSummary/image1.png", "value": img239},
  {"key": "images/StewardshipSummary/image2.png", "value": img240},
  {"key": "images/StructuralSystemUpgrade/image1.png", "value": img241},
  {"key": "images/StructuralSystemUpgrade/image2.png", "value": img242},
  {"key": "images/StructuralSystemUpgrade/image3.png", "value": img243},
  {"key": "images/SuitabilityOfFireProtectionMeasures/image1.png", "value": img244},
  {"key": "images/SuitabilityOfFireProtectionMeasures/image2.png", "value": img245},
  {"key": "images/SuitabilityOfFireProtectionMeasures/image3.png", "value": img246},
  {"key": "images/TornadoHazardClass/image1.png", "value": img247},
  {"key": "images/TornadoHazardClass/image2.png", "value": img248},
  {"key": "images/TornadoHazardClass/image3.png", "value": img249},
  {"key": "images/TornadoHazardClass/image4.png", "value": img250},
  {"key": "images/TornadoHazardClass/image5.png", "value": img251},
  {"key": "images/TornadoHazardClass/image6.png", "value": img252},
  {"key": "images/TsunamiHazardClass/image1.png", "value": img253},
  {"key": "images/TsunamiHazardClass/image2.png", "value": img254},
  {"key": "images/TsunamiHazardClass/image3.png", "value": img255},
  {"key": "images/TsunamiHazardClass/image4.png", "value": img256},
  {"key": "images/TsunamiHazardClass/image5.png", "value": img257},
  {"key": "images/TsunamiHazardClass/image6.png", "value": img258},
  {"key": "images/WaterSupplyFlowRate/image1.png", "value": img259},
  {"key": "images/WaterSupplyFlowRate/image2.png", "value": img260},
  {"key": "images/WildfireHazardClass/image1.png", "value": img261},
  {"key": "images/WildfireHazardClass/image2.png", "value": img262},
  {"key": "images/WildfireHazardClass/image3.png", "value": img263},
  {"key": "images/WildfireHazardClass/image4.png", "value": img264},
  {"key": "images/WildfireHazardClass/image5.png", "value": img265},
  {"key": "images/WildfireHazardClass/image6.png", "value": img266},
  {"key": "images/WindHazardClass/image1.png", "value": img267},
  {"key": "images/WindHazardClass/image2.png", "value": img268},
  {"key": "images/WindHazardClass/image3.png", "value": img269},
  {"key": "images/WindHazardClass/image4.png", "value": img270},
  {"key": "images/WindowFrameType/image1.png", "value": img271},
  {"key": "images/WindowFrameType/image2.png", "value": img272},
  {"key": "images/WindowGlazingProtection/image1.png", "value": img273},
  {"key": "images/WindowGlazingProtection/image2.png", "value": img274},
  {"key": "images/WindowGlazingProtection/image3.png", "value": img275},
  {"key": "images/YearBuilt/image1.png", "value": img276},
  {"key": "images/YearBuilt/image2.png", "value": img277},
  {"key": "images/YearBuilt/image3.png", "value": img278},
  {"key": "images/YearBuilt/image4.png", "value": img279},
  {"key": "images/primaryTenantNAICS/image1.png", "value": img280},
  {"key": "images/primaryTenantNAICS/image2.png", "value": img281},
  {"key": "images/primaryTenantNAICS/image3.png", "value": img282},
  {"key": "images/primaryTenantNAICS/image4.png", "value": img283},
  {"key": "images/primaryTenantNAICS/image5.png", "value": img284},
];