import React, { FC, useState, useEffect } from 'react';
import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiContextMenuItem, EuiContextMenuPanel, EuiPopover,
} from '@elastic/eui';
import { useAuth0 } from './auth/wrapper';
import '@elastic/eui/dist/eui_theme_light.css';
import { useHistory } from 'react-router-dom';
import { LogOut } from 'react-feather';

export const HeaderUserMenu: FC = () => {
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0()

  const [isOpen, setIsOpen] = useState(false);
  const [fullname, setFullname] = useState<string>('');

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (getIdTokenClaims) {
      (async () => {
        const claims = await getIdTokenClaims() as any;
        if (claims && claims.given_name && claims.family_name) {
          setFullname(claims.given_name + " " + claims.family_name);
        } else {
          setFullname(claims?.nickname || '');
        }
      })();
    }
  }, [getIdTokenClaims])
  
  const button = <>
    <EuiButtonEmpty onClick={onMenuButtonClick}>
      <EuiAvatar
        size="m"
        name={fullname}
        initialsLength={2}
      />
    </EuiButtonEmpty>
  </>;

  const items = [
    <EuiContextMenuItem
      key="Logout"
      icon={<LogOut size="16" />}
      className="dangerMenuItem"
      onClick={() => {
        closePopover();
        history.push({
          pathname: `/logout`,
        });
      }}
    >
      Logout
    </EuiContextMenuItem>,
  ];

  return <>
    <EuiPopover
      id="singlePanel"
      button={button}
      isOpen={isOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downRight"
      style={{
        verticalAlign: "text-bottom"
      }}
    >
      <EuiContextMenuPanel items={items} />
    </EuiPopover>
  </>
}

export default HeaderUserMenu;