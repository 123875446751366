import React, { FC, useState, Fragment } from 'react';
import { Attribute } from '../types';
import { EuiSuperSelect, EuiText, EuiFieldText } from '@elastic/eui';
import { EntityValues } from '../Browser';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

interface ISearchProps {
  selectedAttribute: Attribute;
  attributes: Array<Attribute>;
  entity: EntityValues;
  onClick?: (attribute: Attribute) => void;
  onEntityChange?: (entity: EntityValues) => void;
}

export const Search: FC<ISearchProps> = ({
  selectedAttribute,
  attributes,
  entity,
  onClick,
  onEntityChange,
}) => {
  const [filteredAttributes, setFilteredAttributes] =
    useState<Array<Attribute>>(attributes);
  const [searchTextValue, setSearchTextValue] = useState('');
  const [navRows, setNavRows] = useState();
  const [dropdownEntity, setDropdownEntity] = useState(entity);

  useEffect(() => {
    setFilteredAttributes(attributes);
    setSearchTextValue('');
  }, [attributes]);

  useEffect(() => {
    const valueLower = (searchTextValue || '').toLowerCase();
    setFilteredAttributes(
      attributes.filter((attribute) => {
        return (
          attribute.displayName.toLowerCase().includes(valueLower) ||
          attribute.attributeName.toLowerCase().includes(valueLower) ||
          attribute.markdown.toLowerCase().includes(valueLower)
        );
      })
    );
  }, [searchTextValue]);

  useEffect(() => {
    let rows: any = [];
    filteredAttributes.map((attr, i) => {
      var fa = filteredAttributes[i];
      const cls = selectedAttribute.filename === fa.filename ? 'selected' : '';

      return rows.push(
        <li
          className={cls}
          onClick={(e) => {
            if (onClick) {
              return onClick(fa);
            }
            return;
          }}
          key={i}
        >
          {fa.displayName}
        </li>
      );
    });
    setNavRows(rows);
  }, [selectedAttribute, filteredAttributes]);

  const entityChangeEvent = (value: any) => {
    mixpanel.track(`Schema Docs Browser: Changed data type`, {
      event_surface: 'SchemaDocsBrowser',
      previousDataType: dropdownEntity,
      newDataType: value,
    });

    setDropdownEntity(value);
    if (onEntityChange) {
      onEntityChange((value + '').toLowerCase() as any);
    }
  };

  const options = [
    {
      value: 'property',
      inputDisplay: 'Property',
      dropdownDisplay: (
        <Fragment>
          <strong>Property</strong>
          <EuiText size="s" color="subdued">
            <p className="euiTextColor--subdued">
              Navigate the schema docs for Property
            </p>
          </EuiText>
        </Fragment>
      ),
    },
    {
      value: 'program',
      inputDisplay: 'Program',
      dropdownDisplay: (
        <Fragment>
          <strong>Program</strong>
          <EuiText size="s" color="subdued">
            <p className="euiTextColor--subdued">
              Navigate the schema docs for Program
            </p>
          </EuiText>
        </Fragment>
      ),
    },
    {
      value: 'policy',
      inputDisplay: 'Policy',
      dropdownDisplay: (
        <Fragment>
          <strong>Policy</strong>
          <EuiText size="s" color="subdued">
            <p className="euiTextColor--subdued">
              Navigate the schema docs for Policy
            </p>
          </EuiText>
        </Fragment>
      ),
    },
    {
      value: 'loss',
      inputDisplay: 'Loss',
      dropdownDisplay: (
        <Fragment>
          <strong>Loss</strong>
          <EuiText size="s" color="subdued">
            <p className="euiTextColor--subdued">
              Navigate the schema docs for Loss
            </p>
          </EuiText>
        </Fragment>
      ),
    },
  ];

  return (
    <div
      className="navbar"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          padding: '8px',
        }}
      >
        <EuiSuperSelect
          options={options}
          valueOfSelected={dropdownEntity}
          onChange={(value: any) => entityChangeEvent(value)}
          itemLayoutAlign="top"
          hasDividers
        />
      </div>
      <div className="search">
        <EuiFieldText
          placeholder="Search..."
          value={searchTextValue}
          onChange={(e: any) => {
            setSearchTextValue(e.target.value);
          }}
        />
      </div>
      <div className="list">
        <ul>{navRows}</ul>
      </div>
    </div>
  );
};
