import React, { FC } from "react";
import { useAuth0 } from "./auth/hooks";
import LoadingPage from "./Pages/LoadingPage";

export const Callback: FC = () => {
  const { loading, isAuthenticated, handleRedirectCallback } = useAuth0();
  if (loading) {
    return <LoadingPage />
  } else if (isAuthenticated) {
    window.location.href = '/';
    return <></>
  }
  
  if (handleRedirectCallback) {
    if (window.location.search.includes("code=")) {
      handleRedirectCallback();
    }
  }
  return <LoadingPage />
}

export default Callback;