import { EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiText, EuiTitle } from "@elastic/eui";
import React, { FC } from "react";

interface IProps {
  message: string
}

export const CustomErrorPage: FC<IProps> = ({
  message,
}) => {
  return <EuiPage style={{ height: "100vh" }}>
    <EuiPageBody>
      <EuiPageContent verticalPosition="center" horizontalPosition="center">
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h2>An error occured</h2>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>

          <EuiText>
            <p>{message}</p>
          </EuiText>

        </EuiPageContentBody>
      </EuiPageContent>
    </EuiPageBody>
  </EuiPage>
}

export default CustomErrorPage;